"use client";

import Link from "next/link";
import { useTranslation } from "react-i18next";
import Routes from "@/app/constants/routes";
import Translations from "@/app/constants/translations";
import { BONUS, Package } from "@/app/constants/packages";
import { PriceConverter } from "@/app/utils/priceConverter";
import getEvent from "@/app/utils/getEvent";

interface PricingCardProps {
    package: Package;
};

export default function PricingCard(props: PricingCardProps) {
    const bonusCredits: number = Math.round(props.package.credits * BONUS);
    const discount: number = getEvent()?.discount || 0;
    const centsPrice: number = props.package.price * (1 - discount / 100);
    const price: number = PriceConverter.centsToUnits(centsPrice);
    const { t } = useTranslation(Translations.HOME);

    return (
        <li
            className={
                `flex flex-col rounded-3xl gap-8 p-6 shadow-lg 
                dark:shadow-none
                ${props.package.popular ? `bg-gradient-to-br from-amber-300 
                to-amber-400` : "bg-neutral-100 dark:bg-neutral-900"}`
            }
        >
            <section className="flex flex-col gap-8">
                <section className="flex flex-col gap-2">
                    <section className="flex flex-row justify-between gap-4">
                        <span
                            className={
                                `font-semibold text-neutral-950 
                                ${!props.package.popular && 
                                "dark:text-neutral-50"}`
                            }
                        >
                            {t(`${Translations.HOME}:pricing.card.title`, {
                                creditsNumber: (
                                    props.package.credits + bonusCredits
                                )
                            })}
                        </span>
                        {props.package.popular && (
                            <span
                                className={
                                    `rounded-xl py-1 px-2 border text-xs 
                                    text-neutral-700 border-neutral-700 
                                    ${!props.package.popular && 
                                    `dark:border-neutral-200 
                                    dark:text-neutral-200`}`
                                }
                            >
                                {t(
                                    `${
                                        Translations.HOME
                                    }:pricing.card.popular`
                                )}
                            </span>
                        )}
                    </section>
                    <span
                        className={
                            `text-xs text-neutral-700 
                            ${!props.package.popular && 
                            "dark:text-neutral-300"}`
                        }
                    >
                        {t(`${Translations.HOME}:pricing.card.description`, {
                            creditsNumber: bonusCredits
                        })}
                    </span>
                </section>
                <section className="flex flex-row gap-2 items-end">
                    <section className="flex flex-col">
                        {!!discount && (
                            <span
                                className={
                                    `relative tracking-tighter font-bold
                                    text-3xl text-neutral-950 w-fit opacity-50
                                    ${!props.package.popular && 
                                    "dark:text-neutral-50"}`
                                }
                            >
                                {t(`${Translations.HOME}:pricing.card.price`, {
                                    price: PriceConverter.centsToUnits(
                                        props.package.price
                                    )
                                })}
                                <figure className={
                                    `absolute h-1 w-[120%] bg-neutral-950 
                                    -rotate-12 top-[40%] -left-[5%]
                                    ${!props.package.popular && 
                                    "dark:bg-neutral-50"}`
                                } />
                            </span>
                        )}
                        <h3
                            className={
                                `tracking-tighter font-bold text-6xl 
                                text-neutral-950
                                ${!props.package.popular && 
                                "dark:text-neutral-50"}`
                            }
                        >
                            {t(`${Translations.HOME}:pricing.card.price`, {
                                price: price
                            })}
                        </h3>
                    </section>
                    <span
                        className={
                            `text-sm text-neutral-950 
                            ${!props.package.popular && 
                            "dark:text-neutral-50"}`
                        }
                    >
                        {t(`${Translations.HOME}:pricing.card.recurring`)}
                    </span>
                </section>
            </section>
            <Link href={Routes.LOGIN}>
                <button 
                    className={
                        `rounded-full w-full max-w-72 px-4 py-2 
                        truncate text-center hover:scale-105 hover:shadow-sm 
                        transition-all ease-in-out duration-300
                        ${props.package.popular ? `bg-neutral-50 
                        dark:bg-neutral-950` : 
                        `bg-gradient-to-br from-rose-500 to-rose-600`}`
                    }
                >
                    <span 
                        className={
                            `font-semibold text-xl text-neutral-50
                            ${props.package.popular && 
                            "text-neutral-950 dark:text-neutral-50"}`
                        }
                    >
                        {t(`${Translations.HOME}:affiliate.button`)}
                    </span>
                </button>
            </Link>
        </li>
    );
}
