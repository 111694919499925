import { Event, EVENTS } from "../constants/events";
import normalizeDate from "./normalizeDate";

export default function getEvent(): Event | null {
    return EVENTS.find((event: Event) => {
        const now: Date = new Date();
        const startDate: Date = normalizeDate(event.startDate, false);
        const endDate: Date = normalizeDate(event.endDate, true);
        return now >= startDate && now <= endDate;
    }) || null;
}
