"use client";

import { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import Translations from "@/app/constants/translations";

export interface FaqAccordionProps {
    question: string;
    answer: {
        key: string;
        options?: Record<string, string | number>;
    };
    isFirstElement?: boolean;
};

export default function FaqAccordion(props: FaqAccordionProps) {
    const { t } = useTranslation(Translations.FAQ);
    // State to manage the open/close state of the accordion.
    const [isOpen, setIsOpen] = useState(false);
    // State to manage the height of the accordion content.
    const [height, setHeight] = useState("0px");
    const contentRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const height: number = contentRef?.current?.scrollHeight ?? 0;
        setHeight(`${isOpen ? height : 0}px`);
    }, [isOpen]);

    const handleToggle = (
        event: React.MouseEvent<HTMLMapElement, MouseEvent>
    ) => {
        event.preventDefault();
        setIsOpen(!isOpen);
    }

    return (
        <>
            {!props.isFirstElement && (
                <hr
                    className="w-full border-neutral-300"
                />
            )}
            <section
                className="overflow-hidden flex flex-col"
            >
                <summary 
                    onClick={handleToggle}
                    className="block cursor-pointer font-medium py-3
                    text-neutral-950 lg:text-lg dark:text-neutral-50"
                >
                    <section
                        className="flex flex-row justify-between items-center 
                        gap-4"
                    >
                        {t(props.question)}
                        <motion.span
                            initial={{ rotate: 0 }}
                            animate={{ rotate: isOpen ? 180 : 0 }}
                            transition={{ duration: 0.3 }}
                        >
                            <ChevronDownIcon className="w-4 h-4" />
                        </motion.span>
                    </section>
                </summary>
                <motion.div
                    ref={contentRef}
                    initial={{ 
                        height: 0,
                        opacity: 0
                    }}
                    animate={{
                        height: isOpen ? height : "0px",
                        opacity: isOpen ? 1 : 0,
                    }}
                    className={
                        `overflow-hidden ${isOpen ? "mb-4" : ""} 
                        text-neutral-700 dark:text-neutral-200 
                        transition-all duration-300 ease-in-out`
                    }
                >
                    {t(props.answer.key, props.answer.options)}
                </motion.div>
            </section>
        </>
    );
};
