"use client";

import { Event, EventType } from "@/app/constants/events";
import Translations from "@/app/constants/translations";
import { Trans, useTranslation } from "react-i18next";

export default function DiscountAnnouncement(props: Event) {
    const { t } = useTranslation(Translations.HEADER);

    let name: string;
    if (props.type == EventType.NEW_YEAR) {
        name = t(props.name, { year: new Date().getFullYear() });
    } else name = t(props.name);

    let key: string;
    if (
        props.startDate.getFullYear() === props.endDate.getFullYear()
        && props.startDate.getMonth() === props.endDate.getMonth()
        && props.startDate.getDate() === props.endDate.getDate()
    ) {
        key = `${Translations.HEADER}:announcement.discount-day`;
    } else key = `${Translations.HEADER}:announcement.discount`;

    return (
        <span>
            <Trans
                i18nKey={key}
                values={{
                    name: name,
                    discount: props.discount,
                    endDate: props.endDate,
                }}
                components={{
                    bold: <span className="font-bold" />,
                }}
            />
        </span>
    );
}
